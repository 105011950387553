.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.title {
  margin-bottom: 20px;
  text-align: center;
}

.pdf-section {
  margin-top: 20px;
}

.pdf-container {
  width: 100%;
  height: 500
}

.signature-canvas {
  width: 100%;
  height: 500;
  border: dashed;
}

.dropzone {
  border: 2px dashed #000;
  padding: 20px;
  border: dashed;
}

.drag-over {
  border-color: #ff0000;
}


.body {
   
    background-image: url('https://static.wixstatic.com/media/11062b_8359ef5b7ba143b089f6d735e480c3ce~mv2.jpg/v1/fit/w_924,h_520/11062b_8359ef5b7ba143b089f6d735e480c3ce~mv2.jpg');
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  }
